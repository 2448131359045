<template>
  <div>
    <div v-if="serviceFee.status">
      <div class="btn btn-icon w-auto btn-light btn-clean d-inline-flex align-items-center btn-lg px-4 mr-3"  @click="openEditServiceFeeModal">
        <span class="text-dark-50 font-weight-bolder font-size-base mr-1">{{ serviceFee.amount }} TL</span>
        <span class="text-muted font-weight-bold font-size-base mr-1">/</span>
        <span class="text-muted font-weight-bold font-size-base">araç başı</span>
      </div>
      <b-button
        v-b-tooltip.hover
        type="button"
        class="btn btn-icon btn-light-danger btn-hover-light-danger btn-lg"
        data-toggle="tooltip"
        title="Servis ücretini kaldır"
        @click="removeServiceFee"
      >
        <i class="flaticon2-trash icon-md text-danger"></i>
      </b-button>
    </div>
    <button type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center" v-if="!serviceFee.status" @click="$bvModal.show('modal-add-airport-pickup-service-fee')">
      <i class="flaticon2-add-1 icon-sm"></i>
      <span>Servis ücreti ekle</span>
    </button>

     <!--Begin: Add service fee modal-->
    <b-modal id="modal-add-airport-pickup-service-fee" ref="modal-add-airport-pickup-service-fee" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddServiceFeeModalCancel" @hide="handleAddServiceFeeModalCancel" @ok="handleAddServiceFeeModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>Araç başı</label>
            <h5>Servis ücreti gir</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div class="form-group">
          <label class="text-capitalize">Araç Başı Ücret</label>
          <div class="input-group input-group-lg">
            <div class="input-group-prepend">
              <span class="input-group-text font-weight-bold text-dark">
                TRY
              </span>
            </div>
            <input
              type="number"
              class="form-control form-control-lg"
              placeholder="Servis ücreti"
              v-model="addServiceFeeModal.amount"
            />
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addServiceFeeModal.amount == 0 || addServiceFeeModal.amount == ''"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add service fee modal-->

    <!--Begin: Edit service fee modal-->
    <b-modal id="modal-edit-airport-pickup-service-fee" ref="modal-edit-airport-pickup-service-fee" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleEditServiceFeeModalCancel" @ok="handleEditServiceFeeModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>Araç başı</label>
            <h5>Servis ücreti gir</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div class="form-group">
          <label class="text-capitalize">Araç Başı Ücret</label>
          <div class="input-group input-group-lg">
            <div class="input-group-prepend">
              <span class="input-group-text font-weight-bold text-dark">
                TRY
              </span>
            </div>
            <input
              type="number"
              class="form-control form-control-lg"
              placeholder="Servis ücreti"
              v-model="editServiceFeeModal.amount"
            />
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="editServiceFeeModal.amount == 0 || editServiceFeeModal.amount == ''"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Edit service fee modal-->
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
export default {
  name: 'ExperienceWizardHotelPickUpServiceFee',
  props: ['experienceId'],
  data() {
    return {
      serviceFee: {
        status: false,
        amount: 0
      },
      addServiceFeeModal: {
        amount: ''
      },
      editServiceFeeModal: {
        amount: ''
      }
    }
  },
  mounted() {
    this.getServiceFee();
  },
  methods: {
    getServiceFee() {
      ApiService.get(`experience/meeting/airport-pickup/service-fee/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.serviceFee.status = data.serviceFeeStatus === 1;
        this.serviceFee.amount = data.serviceFeeAmount;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    setServiceFee(feeAmount) {
      const data = {
        feeAmount,
        id: this.experienceId,
      }

      ApiService.post('experience/meeting/airport-pickup/service-fee/set.req.php', data)
      .then(() => {
        this.serviceFee.amount = feeAmount;
        this.serviceFee.status = true;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    removeServiceFee() {
      const data = {
        feeAmount: 0,
        id: this.experienceId,
      }

      ApiService.post('experience/meeting/airport-pickup/service-fee/set.req.php', data)
      .then(() => {
        this.serviceFee.amount = 0;
        this.serviceFee.status = false;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    handleAddServiceFeeModalCancel() {
      this.addServiceFeeModal.amount = '';
    },

    handleAddServiceFeeModalOk() {
      const serviceFeeAmount = parseFloat(this.addServiceFeeModal.amount);
      this.setServiceFee(serviceFeeAmount);
    },

    openEditServiceFeeModal() {
      const serviceFeeAmount = this.serviceFee.amount;
      this.editServiceFeeModal.amount = serviceFeeAmount;
      this.$bvModal.show('modal-edit-airport-pickup-service-fee');
    },

    handleEditServiceFeeModalCancel() {
      this.editServiceFeeModal.amount = '';
    },

    handleEditServiceFeeModalOk() {
      const serviceFeeAmount = parseFloat(this.editServiceFeeModal.amount);
      this.setServiceFee(serviceFeeAmount);
    }
  }
}
</script>

<style>

</style>