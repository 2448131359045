<template>
  <div>
    <div v-if="serviceFee.status">
      <div class="btn btn-icon w-auto btn-light btn-clean d-inline-flex align-items-center btn-lg px-4 mr-3"  @click="openEditServiceFeeModal">
        <span class="text-dark-50 font-weight-bolder font-size-base mr-1">{{ serviceFee.amount }} TL</span>
        <span class="text-muted font-weight-bold font-size-base mr-1">/</span>
        <span class="text-muted font-weight-bold font-size-base">{{ serviceFeeTypeText(serviceFee.type) }}</span>
      </div>
      <b-button
        v-b-tooltip.hover
        type="button"
        class="btn btn-icon btn-light-danger btn-hover-light-danger btn-lg"
        data-toggle="tooltip"
        title="Servis ücretini kaldır"
        @click="removeServiceFee"
      >
        <i class="flaticon2-trash icon-md text-danger"></i>
      </b-button>
    </div>
    <button type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center" v-if="!serviceFee.status" @click="$bvModal.show('modal-add-hotel-pickup-service-fee')">
      <i class="flaticon2-add-1 icon-sm"></i>
      <span>Servis ücreti ekle</span>
    </button>

     <!--Begin: Add service fee modal-->
    <b-modal id="modal-add-hotel-pickup-service-fee" ref="modal-add-hotel-pickup-service-fee" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddServiceFeeModalCancel" @hide="handleAddServiceFeeModalCancel" @ok="handleAddServiceFeeModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addServiceFeeModal.activeStep }} ADIM/2</label>
            <h5>{{ addServiceFeeModal.steps[addServiceFeeModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-show="addServiceFeeModal.activeStep === 1">
          <div class="form-group">
            <label class="option selectable" :class="{ selected: addServiceFeeModal.feeType == 1 }">
                <span class="option-control">
                  <span class="radio radio-dark">
                    <input
                      type="radio"
                      name="radio_service_fee_type"
                      value="1"
                      v-model="addServiceFeeModal.feeType"
                    />
                    <span></span>
                  </span>
                </span>
                <span class="option-label">
                  <span class="option-head">
                    <span class="option-title">
                      Kişi başı
                    </span>
                  </span>
                </span>
            </label>
          </div>
          <div class="form-group">
            <label class="option selectable" :class="{ selected: addServiceFeeModal.feeType == 2 }">
              <span class="option-control">
                <span class="radio radio-dark">
                  <input
                    type="radio"
                    name="radio_service_fee_type"
                    value="2"
                    v-model="addServiceFeeModal.feeType"
                  />
                  <span></span>
                </span>
              </span>
              <span class="option-label">
                <span class="option-head">
                  <span class="option-title">
                    Araç başı
                  </span>
                </span>
              </span>
            </label>
          </div>
        </div>
        <div v-show="addServiceFeeModal.activeStep === 2">
          <div class="form-group">
            <label class="text-capitalize">{{ serviceFeeTypeText(addServiceFeeModal.feeType) }} ücret</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  TRY
                </span>
              </div>
              <input
                type="number"
                class="form-control form-control-lg"
                placeholder="Servis ücreti"
                v-model="addServiceFeeModal.amount"
              />
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addServiceFeeModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addServiceFeeModal.activeStep = addServiceFeeModal.activeStep - 1" 
            v-if="addServiceFeeModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addServiceFeeModal.amount == 0 || addServiceFeeModal.amount == ''"
            v-if="addServiceFeeModal.activeStep == addServiceFeeModal.steps.length">
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addServiceFeeModal.feeType == null"
            @click="addServiceFeeModal.activeStep = addServiceFeeModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add service fee modal-->

    <!--Begin: Add service fee modal-->
    <b-modal id="modal-edit-hotel-pickup-service-fee" ref="modal-add-hotel-pickup-service-fee" :no-close-on-backdrop="true" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleEditServiceFeeModalCancel" @ok="handleEditServiceFeeModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ editServiceFeeModal.activeStep }} ADIM/2</label>
            <h5>{{ editServiceFeeModal.steps[editServiceFeeModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-show="editServiceFeeModal.activeStep === 1">
          <div class="form-group">
            <label class="option selectable" :class="{ selected: editServiceFeeModal.feeType == 1 }">
                <span class="option-control">
                  <span class="radio radio-dark">
                    <input
                      type="radio"
                      name="radio_service_fee_type"
                      value="1"
                      v-model="editServiceFeeModal.feeType"
                    />
                    <span></span>
                  </span>
                </span>
                <span class="option-label">
                  <span class="option-head">
                    <span class="option-title">
                      Kişi başı
                    </span>
                  </span>
                </span>
            </label>
          </div>
          <div class="form-group">
            <label class="option selectable" :class="{ selected: editServiceFeeModal.feeType == 2 }">
              <span class="option-control">
                <span class="radio radio-dark">
                  <input
                    type="radio"
                    name="radio_service_fee_type"
                    value="2"
                    v-model="editServiceFeeModal.feeType"
                  />
                  <span></span>
                </span>
              </span>
              <span class="option-label">
                <span class="option-head">
                  <span class="option-title">
                    Araç başı
                  </span>
                </span>
              </span>
            </label>
          </div>
        </div>
        <div v-show="editServiceFeeModal.activeStep === 2">
          <div class="form-group">
            <label class="text-capitalize">{{ serviceFeeTypeText(editServiceFeeModal.feeType) }} ücret</label>
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold text-dark">
                  TRY
                </span>
              </div>
              <input
                type="number"
                class="form-control form-control-lg"
                placeholder="Servis ücreti"
                v-model="editServiceFeeModal.amount"
              />
            </div>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="editServiceFeeModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="editServiceFeeModal.activeStep = editServiceFeeModal.activeStep - 1" 
            v-if="editServiceFeeModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="editServiceFeeModal.amount == 0 || editServiceFeeModal.amount == ''"
            v-if="editServiceFeeModal.activeStep == editServiceFeeModal.steps.length">
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="editServiceFeeModal.feeType == null"
            @click="editServiceFeeModal.activeStep = editServiceFeeModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add service fee modal-->
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
export default {
  name: 'ExperienceWizardHotelPickUpServiceFee',
  props: ['experienceId'],
  data() {
    return {
      serviceFee: {
        status: false,
        amount: 0,
        type: 0
      },
      addServiceFeeModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Ücretlendirme tipi seç'
          },
          {
            number: 2,
            title: 'Servis ücreti gir'
          }
        ],
        feeType: null,
        amount: ''
      },
      editServiceFeeModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Ücretlendirme tipi seç'
          },
          {
            number: 2,
            title: 'Servis ücreti gir'
          }
        ],
        feeType: null,
        amount: ''
      }
    }
  },
  mounted() {
    this.getServiceFee();
  },
  methods: {
    getServiceFee() {
      ApiService.get(`experience/meeting/hotel-pickup/service-fee/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.serviceFee.status = data.serviceFeeStatus === 1;
        this.serviceFee.amount = data.serviceFeeAmount;
        this.serviceFee.type = data.serviceFeeType;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    setServiceFee(feeType, feeAmount) {
      const data = {
        feeAmount,
        feeType,
        id: this.experienceId,
      }

      ApiService.post('experience/meeting/hotel-pickup/service-fee/set.req.php', data)
      .then(() => {
        this.serviceFee.amount = feeAmount;
        this.serviceFee.type = feeType;
        this.serviceFee.status = true;
        this.error.status = false;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
        /*this.error.message = response.data.errorMessage;
        this.error.status = true;*/
      });
    },

    removeServiceFee() {
      const data = {
        feeAmount: 0,
        feeType: 0,
        id: this.experienceId,
      }

      ApiService.post('experience/meeting/hotel-pickup/service-fee/set.req.php', data)
      .then(() => {
        this.serviceFee.amount = 0;
        this.serviceFee.type = 0;
        this.serviceFee.status = false;
        this.error.status = false;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    serviceFeeTypeText(type) {
      if (type == 1) {
        return 'kişi başı';
      } else if (type == 2) {
        return 'araç başı';
      } else { return ''; }
    },

    handleAddServiceFeeModalCancel() {
      this.addServiceFeeModal.activeStep = 1;
      this.addServiceFeeModal.feeType = null;
      this.addServiceFeeModal.amount = '';
    },

    handleAddServiceFeeModalOk() {
      const serviceFeeAmount = parseFloat(this.addServiceFeeModal.amount);
      const serviceFeeType = parseInt(this.addServiceFeeModal.feeType);
      this.setServiceFee(serviceFeeType, serviceFeeAmount);
    },

    openEditServiceFeeModal() {
      const serviceFeeAmount = this.serviceFee.amount;
      const serviceFeeType = this.serviceFee.type;
      this.editServiceFeeModal.amount = serviceFeeAmount;
      this.editServiceFeeModal.feeType = serviceFeeType;
      this.$bvModal.show('modal-edit-hotel-pickup-service-fee');
    },

    handleEditServiceFeeModalCancel() {
      this.editServiceFeeModal.activeStep = 1;
      this.editServiceFeeModal.feeType = null;
      this.editServiceFeeModal.amount = '';
    },

    handleEditServiceFeeModalOk() {
      const serviceFeeAmount = parseFloat(this.editServiceFeeModal.amount);
      const serviceFeeType = parseInt(this.editServiceFeeModal.feeType);
      this.setServiceFee(serviceFeeType, serviceFeeAmount);
    }
  }
}
</script>

<style>

</style>